.add-material-icon {
  font-size: 30px;
  position: absolute;
  right: 20px;
} 

.material-description {
  margin-bottom: 10px;
}

.details-label {
  font-weight: bold;
}

ion-segment-button {
  min-width: auto;
}

ion-segment-button ion-icon {
  margin: 0;
}