.stock-list-item {
  margin: 7px 10px 7px 10px;
  border: none;
  --inner-border-width: 0;
  border-radius: 10px;
  box-shadow: #3a7be088 0px 0px 5px;
}

.list-button {
  height: 100%;
  width: 100%;
  margin: 0;
}

.list-button::part(native) {
  padding: 0;
}

.list-button-icon {
  width: 70%;
  height: 100%
};
