.sig-container {  
  height: 90%;
  margin: 5px;
  border: solid 1px black;
  background-color: #fff;
}

.sig-pad {
  width: 100%;
  height: 100%;
}