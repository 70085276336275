.custom-alert
{
    padding: 10px;
    margin: 10px;
    border: solid;
    border-radius: 10px;
}

.custom-alert.error {
    background-color: rgba(255, 0, 0, 0.5);
}
.custom-alert.warning {
    background-color: rgba(255, 255, 0, 0.5);
}
.custom-alert.info {
    background-color: rgba(50, 125, 255, 0.5);
}
.custom-alert.success {
    background-color: rgba(0, 150, 0, 0.5);
}

.custom-alert .icon {
    display: inline-block;
    font-size: 30px;
    vertical-align: middle;
}

.custom-alert .text{
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
}