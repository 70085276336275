/* Dashed border */
hr.dashed {
    border-top: 1px dashed #bbb;
}

/* Dotted border */
hr.dotted {
    border-top: 1px dotted #bbb;
}

/* Solid border */
hr.solid {
    border-top: 1px solid #bbb;
}

/* Rounded border */
hr.rounded {
    border-top: 5px solid #bbb;
    border-radius: 5px;
}

.custom-section-title {
    margin-left: 10px;
}

.noborder-list-item {
    --inner-border-width: 0;
}

.bordered-text-block {
    border: solid 1px #CCCCCC;
    margin: 0 10px;
    padding: 10px;
    min-height: 6em;
}

.bordered-input-textarea {
    border: solid 1px #DDD;
    margin: 10px;
    min-height: 6em;
}

.bordered-input-textarea ion-textarea {
    margin: 0;
}

textarea[disabled] {
    opacity: 1 !important;
}

.item-interactive-disabled ion-label {
    opacity: 1 !important;
}

ion-footer {
    height: calc(45px + constant(safe-area-inset-bottom));
    height: calc(45px + env(safe-area-inset-bottom));
}

ion-footer > ion-button {
    margin: 0;
    height: 100%;
    --padding-bottom: env(safe-area-inset-bottom);
}

.ios ion-app {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
}

ion-item > ion-note {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 50%;
}

ion-footer > ion-button.button-disabled {
    opacity: 1;
    --ion-color-base: #666666 !important;
    --color: #FFFFFF !important;
    --ion-color-contrast: #FFFFFF !important;
}
