.expandable-button-item {
  padding: 10px;
  background: white;
  box-shadow: 0px 1px 3px #888;
  margin: 6px 3px;
  padding: 10px;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.3s;
}

.rotate-0 {
  transition: 0.3s;
}